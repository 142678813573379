import { FeaturePageTemplate, TFeatureItem } from './_featurePageTemplate'
import { FunctionComponent } from 'react'
import reviewGif from './imgs/qa/reviewDemo.gif'
import iaaPng from './imgs/qa/iaa.png'
import modelCOmpGif from './imgs/qa/modelComparison.gif'
const items: TFeatureItem[] = [
  {
    title: 'Annotation Review and Conflict Resolution',
    description:
      'Visually review your teams annotations and build a guild reference. LightTag detects conflicts and lets you auto accept by majority or unanimous vote',
    img: reviewGif,
  },
  {
    title: 'Metrics And Reporting',
    description:
      'Get the agreeement, precision and recall of your annotators and models right now without any code. ',
    img: iaaPng,
  },
  {
    title: 'Review Your Production Models',
    description:
      'Load your production predictions into LightTag and review them to detect data drift and monitor your production model’s performance',
    img: modelCOmpGif,
  },
]
const QA: FunctionComponent = () => (
  <FeaturePageTemplate
    header={'Data Quality Control'}
    subheader={'Labeled Data Is Great. Accurate Data Is Better'}
    featureItems={items}
    googleDescription={'Labeled Data Is Great. Accurate Data Is Better'}
    googleKeywords={[
      'IAA',
      'Inter annotator agreement',
      'data quality',
      'nlp data quality',
    ]}
  />
)

export default QA
